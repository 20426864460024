import { i18n } from "next-i18next";
import flagsDictionary from "./_flags.dict.json";
import theme from "../theme";
import palette from "./lib/palette";
import flatMapDeep from "lodash/flatMapDeep";

// console.log({ i18n })

const atob = require("atob");
const btoa = require("btoa");

const languageSuffixes = {
    pl: ["_pl", ""],
    en: ["_en", "_ang"],
    es: ["_es", "_spa"],
};

export const FlagsDictionary = flagsDictionary;

export const flagsDefinitions = [
    {
        ID: 1,
        KOD: "",
        NAZWA: "Zjawisko wystąpiło",
        NAZWA_ANG: "Zjawisko wystąpiło",
        METHOD: 'wyświetlamy "wartosc"',
    },
    {
        ID: 2,
        KOD: "-",
        NAZWA: "Zjawisko nie wystąpiło",
        NAZWA_ANG: "Magnitude zero",
        METHOD: 'zamiast "wartosc" wyświetlamy KOD czyli "-"',
    },
    {
        ID: 3,
        KOD: "0",
        NAZWA: "Wartość mniejsza niż przyjęty format prezentacji",
        NAZWA_ANG: "Magnitude not zero, but less than 0,5 of a unit",
        METHOD: "",
    },
    {
        ID: 4,
        KOD: "0",
        NAZWA: "Zjawisko istniało w wielkości mniejszej od 0,05",
        NAZWA_ANG: "Magnitude not zero, but less than 0,05 of a unit",
        METHOD: "",
    },
    {
        ID: 5,
        KOD: ".",
        NAZWA: "Brak informacji, konieczność zachowania tajemnicy statystycznej lub że wypełnienie pozycji jest niemożliwe albo niecelowe",
        NAZWA_ANG:
            "Data not available, classified data (statistical confidentiality) or providing data impossible or purposeless",
        METHOD: 'zamiast "wartosc" wyświetlamy KOD czyli "."',
    },
    {
        ID: 6,
        KOD: "*",
        NAZWA: "Dane zostały zmienione w stosunku do wcześniej opublikowanych",
        NAZWA_ANG: "Revised data",
        METHOD: 'wyświetlamy "wartosc" i * czyli np. 99,00*',
    },
];

/** @memberOf useHelper
 * @name Atou()
 * @see atou()*/
export const Atou = (b64) => decodeURIComponent(escape(atob(b64)));

/** @memberOf useHelper
 * @name Utoa()
 * @see utoa()*/
export const Utoa = (data) => btoa(unescape(encodeURIComponent(data)));

/** @memberOf useHelper
 * @name TranslationFromJson()
 * @see translationFromJson()*/
export const TranslationFromJson = (json, key, i18n) => {
    const currentLanguage = i18n?.language || "pl";
    // console.log(json, languageSuffixes, i18n, i18n.language, currentLanguage)
    if (!json) return;
    return json[key + languageSuffixes[currentLanguage].find((suffix) => json[key + suffix])] || json[key];
};

/** @memberOf useHelper
 * @name IntlNumberFormat()
 * @see intlNumberFormat()*/

// export const IntlNumberFormat = (number, precision = 2) => {
//     const currentLanguage = i18n.language === "pl" ? "uk" : i18n.language;
//     return new Intl.NumberFormat(currentLanguage, {
//         minimumFractionDigits: precision,
//         maximumFractionDigits: precision,
//     }).format(number);
// };

export const IntlNumberFormat = (number, precision, id_flagi = 1, forceDOT) => {
    precision = precision === 0 ? 0 : precision > 0 && precision < 6 ? precision : 2;

    const currentLanguage = forceDOT ? "en" : i18n.language === "pl" ? "uk" : i18n.language;

    let formattedNumber = new Intl.NumberFormat(currentLanguage, {
        minimumFractionDigits: precision,
        maximumFractionDigits: precision,
        useGrouping: !forceDOT,
    }).format(number);

    const disvalue = flagsDictionary.find((d) => d.id === id_flagi)?.disvalue;
    const definition = TranslationFromJson(
        flagsDictionary.find((d) => d.id === id_flagi),
        "definition"
    );
    // process.env.NODE_ENV === "development" &&
    // console.log("%c IntlNumberFormat: ", "background: #090; color:#FFF", { id_flagi, disvalue, definition });

    return disvalue ? definition : formattedNumber;

    // console.log({id_flagi})

    switch (id_flagi) {
        case 1:
            return formattedNumber;
        case 2:
            return "-";
        case 3:
            return new Intl.NumberFormat(currentLanguage, {
                minimumFractionDigits: precision,
                maximumFractionDigits: precision,
            }).format(0);
        case 4:
            return new Intl.NumberFormat(currentLanguage, {
                minimumFractionDigits: precision,
                maximumFractionDigits: precision,
            }).format(0);
        case 5:
            return ".";
        case 6:
            return formattedNumber + "*";
        // break;
        case 666:
            break;
        default:
            return formattedNumber + "*";
    }

    return formattedNumber;
};

export const GetAllIDs = (data) => {
    let children = [];

    return data
        ?.map((m) => {
            if (m?.children && m?.children.length) {
                children = [...children, ...(m?.children || [])];
            }
            return m;
        })
        .concat(children.length ? GetAllIDs(children) : children);
};

export const GetAllIDsAlt = (data, level = -1) => {
    let children = [];
    let _data = [];
    level += 1;

    data?.forEach((m) => {
        if (m?.children && m?.children.length) {
            children = [...children, ...(m?.children || [])];
        }
        // _data.push(m);
        _data.push({ ...m, __level: level });
    });
    _data = _data.concat(children.length ? GetAllIDsAlt(children, level) : children);

    return _data;
};

export const GetAllIDsLodash = (item, level = -1) => {
    level += 1;

    if (!item?.children || !item?.children.length) {
        return { ...item, children: [], __level: level };
    }
    // return [item, flatMapDeep(item?.children, (item)=>GetAllIDsLodash(item,level))];
    return [
        {
            ...item,
            // children: item?.children?.filter((f) => f) || [],
            __level: level,
        },
        flatMapDeep(item?.children, (item) => GetAllIDsLodash(item, level)),
    ];
};

export const Delay = async (miliseconds) => {
    await new Promise((resolve) => setTimeout(resolve, miliseconds));
    return true;
};

export const GetStringSizeInPixels = (string = "", fontFamily = "Roboto", fontSize = 16, fontWeight = "normal") => {
    const canvas = document.createElement("canvas");
    const ctx = canvas.getContext("2d");
    ctx.font = `${fontWeight} ${fontSize}px ${fontFamily}`;
    const metrics = ctx.measureText(string);
    return metrics.width;
};

/**
 * Helper function
 * @component
 * @category . Common
 * */
export const useHelper = () => {
    // console.log('useHelper ', i18n)

    /**
     * Get translation of key from json
     * @param {Object} json
     * @param {string} key
     * @return {string}
     * */
    const translationFromJson = (json, key) => TranslationFromJson(json, key, i18n);

    /**
     * Format number using Internationalization API
     * @param {string|number} number
     * @return {string}
     * */
    // const intlNumberFormat = (number, precision) => IntlNumberFormat(number, precision);
    const intlNumberFormat = (number, precision, id_flagi, forceDOT) =>
        IntlNumberFormat(number, precision, id_flagi, forceDOT);

    /**
     * ASCII to Unicode (decode Base64 to original data)
     * @param {string} b64
     * @return {string}
     */
    const atou = (b64) => Atou(b64);

    /**
     * Unicode to ASCII (encode data to Base64)     *
     * @param {string} data
     * @return {string}
     */
    const utoa = (data) => Utoa(data);

    // const getAllIDs = (data) => GetAllIDs(data);
    const getAllIDs = (data) => {
        const t0 = performance.now();
        // const res = GetAllIDs(data);
        // const res = GetAllIDsAlt(data);

        const res = flatMapDeep(data, (item) => GetAllIDsLodash(item, -1));
        // const res = GetAllIDsLodash(data);

        // const res2 = GetAllIDsAlt(data);
        const t1 = performance.now();

        const _caller = new Error("getAllIDs").stack.split("\n")?.[2]?.split("/")?.slice(-1)?.[0]?.replace(")", "");

        if (t1 - t0 > 100 && process.env.NODE_ENV === "development") {
            const _bg = t1 - t0 > 1000 ? "#F00" : "#FF0";
            const _color = t1 - t0 > 1000 ? "#FFF" : "#000";
            process.env.NODE_ENV === "development" &&
                console.log(
                    `%c ${_caller}: getAllIDs took ${t1 - t0} ms (${res?.length} items)`,
                    `background: ${_bg}; color:${_color}`
                );
        }

        return res; //GetAllIDsAlt(data);
    };

    const delay = async (miliseconds) => Delay(miliseconds);

    const getStringSizeInPixels = (string, fontFamily, fontSize, fontWeight) =>
        GetStringSizeInPixels(string, fontFamily, fontSize, fontWeight);

    const generatePaletteForChart = ({ start, end, darkStart, darkEnd, n }) =>
        GeneratePaletteForChart({ start, end, darkStart, darkEnd, n });

    const generateRandomPaletteForChart = ({ n }) => GenerateRandomPaletteForChart({ n });

    const paletteGenerator = ({ scheme, n }) => PaletteGenerator({ scheme, n });

    return {
        translationFromJson,
        intlNumberFormat,
        atou,
        utoa,
        getAllIDs,
        delay,
        getStringSizeInPixels,
        flagsDictionary,
        generatePaletteForChart,
        generateRandomPaletteForChart,
        paletteGenerator,
    };
};

export default useHelper;

/** Palette generator */

export const GeneratePaletteForChart = ({
    start = theme.charts.palette.extendedStart,
    end = theme.charts.palette.extendedEnd,
    darkStart = theme.charts.palette.extendedDarkerStart,
    darkEnd = theme.charts.palette.extendedDarkerEnd,
    n = 7,
}) => {
    // n = n <= 1 ? 2 : n;
    if (n <= 1) {
        return { primary: [start], dark: [darkStart] };
    }

    const hexToRgb = (hex) =>
        hex
            .replace(/^#?([a-f\d])([a-f\d])([a-f\d])$/i, (m, r, g, b) => "#" + r + r + g + g + b + b)
            .substring(1)
            .match(/.{2}/g)
            .map((x) => parseInt(x, 16));

    const rgbToHex = (r, g, b) =>
        "#" +
        [r, g, b]
            .map((x) => {
                const hex = x.toString(16);
                return hex.length === 1 ? "0" + hex : hex;
            })
            .join("");

    const lerp = (a, b, fac) => {
        var ret = [];

        for (var i = 0; i < Math.min(a.length, b.length); i++) {
            ret[i] = Math.round(a[i] * (1 - fac) + b[i] * fac);
        }

        return rgbToHex(ret[0], ret[1], ret[2]);
    };

    var ret = { primary: [], dark: [] };

    for (var i = 0; i < n; i++) {
        var fac = i / (n - 1);
        ret.primary.push(lerp(hexToRgb(start), hexToRgb(end), fac));
        ret.dark.push(lerp(hexToRgb(darkStart), hexToRgb(darkEnd), fac));
    }

    return ret;
};

export const GenerateRandomPaletteForChart = ({ n = 7 }) => {
    const result = [];
    for (let i = 0; i < n; i += 1) {
        const letters = "0123456789ABCDEF".split("");
        let color = "#";
        for (let j = 0; j < 6; j += 1) {
            color += letters[Math.floor(Math.random() * 16)];
        }
        result.push(color);
    }
    return { primary: result, dark: result };
};

export const PaletteGenerator = ({ scheme, n = 7 }) => {
    let result = [];

    switch (scheme) {
        case "mono":
            result = GeneratePaletteForChart({ n })?.primary;
            break;
        case "mpn65":
            result = add("Random", "mpn65", null, n);
            break;
        case "rainbow":
            result = add("Rainbow", "rainbow", 1, n);
            break;

        case "gradient":
            result = add("Red", (x) => palette.rgbColor(x, 0, 0), null, n * 2)?.slice(-n);
            break;
        case "gradient-red":
            result = add("Red", (x) => palette.rgbColor(x, 0, 0), null, n * 2)?.slice(-n);
            break;
        case "gradient-green":
            result = add("Green", (x) => palette.rgbColor(0, x, 0), null, n * 2)?.slice(-n);
            break;
        case "gradient-blue":
            result = add("Blue", (x) => palette.rgbColor(0, 0, x), null, n * 2)?.slice(-n);
            break;
        case "gradient-yellow":
            result = add("Yellow", (x) => palette.rgbColor(x, x, 0), null, n * 2)?.slice(-n);
            break;
        case "gradient-magenta":
            result = add("Magenta", (x) => palette.rgbColor(x, 0, x), null, n * 2)?.slice(-n);
            break;
        case "gradient-cyan":
            result = add("cyan", (x) => palette.rgbColor(0, x, x), null, n * 2)?.slice(-n);
            break;
        case "gradient-grayscale":
            result = add("grayscale", (x) => palette.rgbColor(x, x, x), null, n * 2)?.slice(-n);
            break;

        default:
            break;
    }

    return { primary: result, dark: result };
};

const add = function (title, name, varargs, num) {
    var colors;
    if (typeof name === typeof add && !name.scheme_name) {
        colors = palette.generate(name, num);
    } else {
        var scheme = name;
        if (typeof name === "string") {
            title = name + ": " + title;
            scheme = palette.listSchemes(name)[0];
        }
        var args = Array.prototype.slice.call(arguments, 1);
        args[0] = num;
        colors = scheme.apply(scheme, args);
    }
    if (colors) {
        // colors = transformColors(colors, transform);
        colors = colors.map((color) => `#${color}`);
    }

    return colors;
};
